/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import moment from 'moment-timezone';
import { replaceUrl } from 'utils/urlReplace';
import { WHOEVER__IS_AVAILABLE } from 'utils/constants';
import {
  GET_LIST_ENQUIRIES_WITH_TASKS,
  GET_ENQUIRY_TASK_COUNT,
  MARK_ALL_OVERDUE_TASK_AS_COMPLETED
} from 'urls';
import { MARK_COMPLETE_DEFAULT_TASK } from 'urls/tasks';
import { updateInitialTasksSuborganization } from 'slices/myInfoSlice';
import CHOICES from 'choices';
import { isEmpty } from 'lodash';

const { OVERDUE, DUE_TODAY, NEXT_7_DAYS, BEYOND_7_DAYS } =
  CHOICES.TaskLeadApiFilterChoices;

const initialState = {
  things_to_do_count: {},
  task_due_today: [],
  task_overdue: [],
  task_next_7days: [],
  task_beyond_7days: [],
  activeTabforTasks: DUE_TODAY,
  active_default_task: null,
  activeLeadIdforTask: null,
  hasAssignedUsers: false
};

export const taskThingsToDo = createSlice({
  name: 'taskThingsToDo',
  initialState,
  reducers: {
    setTaskDueToday: (state, action) => {
      state.task_due_today = action.payload;
    },
    setTaskOverdue: (state, action) => {
      state.task_overdue = action.payload;
    },
    setTaskNextSevenDays: (state, action) => {
      state.task_next_7days = action.payload;
    },
    setTaskBeyondSevenDays: (state, action) => {
      state.task_beyond_7days = action.payload;
    },
    setThingsToDoCount: (state, action) => {
      state.things_to_do_count = action.payload;
    },
    setActiveDefaultTask: (state, action) => {
      state.active_default_task = action.payload;
    },
    setActiveTabforTasks: (state, action) => {
      state.activeTabforTasks = action.payload;
    },
    setActiveLeadIdforTasks: (state, action) => {
      state.activeLeadIdforTask = action.payload;
    },
    setHasAssignedUsers: (state, action) => {
      state.hasAssignedUsers = action.payload;
    }
  }
});

export const {
  setTaskDueToday,
  setTaskOverdue,
  setThingsToDoCount,
  setActiveDefaultTask,
  setTaskBeyondSevenDays,
  setTaskNextSevenDays,
  setActiveTabforTasks,
  setActiveLeadIdforTasks,
  setHasAssignedUsers
} = taskThingsToDo.actions;

export const getAllTasks = (payload) => {
  return async (dispatch, getState) => {
    const { activeTabforTasks } = getState().taskThingsToDo;
    dispatch(getTasksOnFilter(payload, activeTabforTasks));
  };
};

export const getTasksOnFilter = (payload = {}, filter) => {
  return async (dispatch, getState) => {
    const thingsToDoLocation = getState().leads.thingsToDoLocation;
    const subOrgId = getState().myInfo.subOrganizationId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const params = {
      task_status: filter,
      location_ids: thingsToDoLocation?.id,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    const { callBack, query = {} } = payload;
    try {
      return await new APIRequest()
        .get(replaceUrl(GET_LIST_ENQUIRIES_WITH_TASKS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setHasAssignedUsers(res.data.has_assigned_users));
            if (filter === DUE_TODAY) {
              dispatch(setTaskDueToday(res.data.data));
            } else if (filter === NEXT_7_DAYS) {
              dispatch(setTaskNextSevenDays(res.data.data));
            } else if (filter === BEYOND_7_DAYS) {
              dispatch(setTaskBeyondSevenDays(res.data.data));
            } else if (filter === OVERDUE) {
              dispatch(setTaskOverdue(res.data.data));
            }
            if (callBack) callBack(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
          if (callBack) callBack();
        });
    } catch (e) {
      console.log(e);
      if (callBack) callBack();
    }
  };
};

export const getTaskEnquiryCount = (payload = {}) => {
  return async (dispatch, getState) => {
    const thingsToDoLocation = getState().leads.thingsToDoLocation;
    const subOrgId = getState().myInfo.subOrganizationId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const things_to_do_count = getState().taskThingsToDo.things_to_do_count;
    const params = {
      location_ids: thingsToDoLocation?.id,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { callback, query = {} } = payload;
      return await new APIRequest()
        .get(replaceUrl(GET_ENQUIRY_TASK_COUNT, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              setThingsToDoCount({
                ...things_to_do_count,
                ...res.data
              })
            );
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const markCompleteDefaultTask = (payload) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const { taskKey, successCb, errorCb } = payload;
    const params = {
      task: taskKey
    };
    try {
      return await new APIRequest()
        .post(replaceUrl(MARK_COMPLETE_DEFAULT_TASK, 'subOrgId', subOrgId), {
          ...params
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              updateInitialTasksSuborganization(
                res?.data?.initial_tasks_suborganization
              )
            );
            if (successCb) {
              successCb();
            }
          } else {
            if (errorCb) {
              errorCb();
            }
          }
        });
    } catch (e) {
      console.log('e', e);
    }
  };
};

export const markAllOverdueTaskAsCompleted = (data, succesCb, failedCb) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      return await new APIRequest()
        .post(
          replaceUrl(MARK_ALL_OVERDUE_TASK_AS_COMPLETED, 'subOrgId', subOrgId),
          data
        )
        .then((res) => {
          if (res.status === 200) {
            if (succesCb) succesCb(data);
          }
        })
        .catch((e) => {
          console.log(e);
          if (failedCb) failedCb();
        });
    } catch (e) {
      console.log(e);
      if (failedCb) failedCb();
    }
  };
};
export const decreaseThingsToDoCount = () => {
  return async (dispatch, getState) => {
    const things_to_do_count = getState().taskThingsToDo.things_to_do_count;
    const enquiry_count = things_to_do_count.enquiry_count;
    dispatch(
      setThingsToDoCount({
        ...things_to_do_count,
        enquiry_count: enquiry_count > 0 ? enquiry_count - 1 : 0
      })
    );
  };
};

export const increaseThingsToDoCount = () => {
  return async (dispatch, getState) => {
    const things_to_do_count = getState().taskThingsToDo.things_to_do_count;
    const enquiry_count = things_to_do_count.enquiry_count;
    dispatch(
      setThingsToDoCount({
        ...things_to_do_count,
        enquiry_count: enquiry_count + 1
      })
    );
  };
};

export const updateThingsToDoCount = (payload) => {
  return async (dispatch, getState) => {
    const things_to_do_count = getState().taskThingsToDo.things_to_do_count;
    dispatch(
      setThingsToDoCount({
        ...things_to_do_count,
        enquiry_count: payload
      })
    );
  };
};

export function filterTask(tasks, tab, timezone = 'UTC') {
  const today = moment().tz(timezone).startOf('day');
  const tomorrow = moment().tz(timezone).add(1, 'day').startOf('day');
  const sevenDaysLater = moment().tz(timezone).add(7, 'days').endOf('day');

  if (isEmpty(tasks)) {
    return false;
  }

  switch (tab) {
    case DUE_TODAY:
      // Check if any date is today (in the specified timezone)
      return tasks.some((task) =>
        moment(task.due_date).tz(timezone).isSame(today, 'day')
      );

    case NEXT_7_DAYS:
      // Check if any date is between tomorrow and 7 days from now (excluding today)
      return tasks.some((task) =>
        moment(task.due_date)
          .tz(timezone)
          .isBetween(tomorrow, sevenDaysLater, 'day', '[]')
      );

    case BEYOND_7_DAYS:
      // Check if any date is beyond the next 7 days (excluding today and next 7 days)
      return tasks.some((task) =>
        moment(task.due_date).tz(timezone).isAfter(sevenDaysLater, 'day')
      );

    case OVERDUE:
      // Check if any date is before today (i.e., overdue)
      return tasks.some((task) =>
        moment(task.due_date).tz(timezone).isBefore(today, 'day')
      );

    default:
      return false;
  }
}

export const shiftTaskLead = (payload) => {
  const { tasksList, card } = payload;
  return async (dispatch, getState) => {
    const activeTabforTasks = getState().taskThingsToDo.activeTabforTasks;
    const myInfo = getState().myInfo.myInformation;
    const { task_due_today, task_next_7days, task_beyond_7days, task_overdue } =
      getState().taskThingsToDo;
    const hasSameRange = filterTask(
      tasksList,
      activeTabforTasks,
      myInfo.timezone
    );
    if (!hasSameRange) {
      switch (activeTabforTasks) {
        case DUE_TODAY: {
          dispatch(
            setTaskDueToday(
              task_due_today?.filter((lead) => lead.lead_id !== card.id)
            )
          );
          break;
        }
        case NEXT_7_DAYS: {
          dispatch(
            setTaskNextSevenDays(
              task_next_7days?.filter((lead) => lead.lead_id !== card.id)
            )
          );
          break;
        }
        case BEYOND_7_DAYS: {
          dispatch(
            setTaskBeyondSevenDays(
              task_beyond_7days?.filter((lead) => lead.lead_id !== card.id)
            )
          );
          break;
        }
        case OVERDUE: {
          dispatch(
            setTaskOverdue(
              task_overdue?.filter((lead) => lead.lead_id !== card.id)
            )
          );
          break;
        }
      }
    }
  };
};

export default taskThingsToDo.reducer;
