// DON'T EDIT. THIS FILE IS GENERATED BY
// ./manage.py export_choices
// CHANGE MADES MANUALLY TO THIS FILE WILL BE LOST
// backend/contribs/management/commands/export_choices.py
const CHOICES = {
  AITaskChoices: {
    AI_DRAFT: 13,
    BOX_GENERATION: 4,
    BOX_GENERATION_WITH_EXAMPLES: 8,
    BOX_GENERATION_WITH_SERVICES: 16,
    BOX_STAGE_LABEL_SUGGEST: 5,
    CHOICES: {
      '-1': 'Unknown',
      0: 'Generate Summary from Home Page',
      1: 'Generate Summary Without Website',
      2: 'Organization Details from Summary',
      3: 'Classify Website Links',
      4: 'Boxes Generation without Industry Examples',
      5: 'Suggest Box Stages (and maybe Labels)',
      6: 'Lead Routing',
      7: 'Lead Labeling',
      8: 'Box Generation with Examples',
      9: 'Summary Conversation',
      10: 'Extract details from the webform',
      11: 'Location Labels for the sub org',
      12: 'Location labels for the incoming lead',
      13: 'Ai Drafting reply',
      14: 'Pick webpages with service urls',
      15: 'Gather services from webpage content',
      16: 'Box generation using labels'
    },
    CHOICE_LIST: [
      {
        label: 'Unknown',
        value: -1
      },
      {
        label: 'Generate Summary from Home Page',
        value: 0
      },
      {
        label: 'Generate Summary Without Website',
        value: 1
      },
      {
        label: 'Organization Details from Summary',
        value: 2
      },
      {
        label: 'Classify Website Links',
        value: 3
      },
      {
        label: 'Boxes Generation without Industry Examples',
        value: 4
      },
      {
        label: 'Suggest Box Stages (and maybe Labels)',
        value: 5
      },
      {
        label: 'Lead Routing',
        value: 6
      },
      {
        label: 'Lead Labeling',
        value: 7
      },
      {
        label: 'Box Generation with Examples',
        value: 8
      },
      {
        label: 'Summary Conversation',
        value: 9
      },
      {
        label: 'Extract details from the webform',
        value: 10
      },
      {
        label: 'Location Labels for the sub org',
        value: 11
      },
      {
        label: 'Location labels for the incoming lead',
        value: 12
      },
      {
        label: 'Ai Drafting reply',
        value: 13
      },
      {
        label: 'Pick webpages with service urls',
        value: 14
      },
      {
        label: 'Gather services from webpage content',
        value: 15
      },
      {
        label: 'Box generation using labels',
        value: 16
      }
    ],
    FIELD_EXTRACTOR: 10,
    GATHER_SERVICES: 15,
    LEAD_LABELING: 7,
    LEAD_ROUTING: 6,
    LOCATION_LABELS: 11,
    LOCATION_LABELS_LEAD: 12,
    ORGANIZATION_DETAILS: 2,
    PICK_SERVICES_URLS: 14,
    SUMMARY: 0,
    SUMMARY_CONVERSATION: 9,
    SUMMARY_WITHOUT_WEBSITE: 1,
    UNKNOWN: -1,
    WEBSITE_LINKS_CLASSIFY: 3
  },
  AddSourceChoices: {
    CHOICES: {
      1: 'Facebook',
      2: 'Google',
      3: 'Google My Business',
      4: 'Instagram',
      5: 'Twitter',
      6: 'Facebook Lead Ads',
      7: 'Manual',
      8: 'Direct Traffic',
      9: 'SMS',
      10: 'Zapier',
      11: 'Whatsapp',
      12: 'CareStack',
      13: 'CS Phones'
    },
    CHOICE_LIST: [
      {
        label: 'Facebook',
        value: 1
      },
      {
        label: 'Google',
        value: 2
      },
      {
        label: 'Google My Business',
        value: 3
      },
      {
        label: 'Instagram',
        value: 4
      },
      {
        label: 'Twitter',
        value: 5
      },
      {
        label: 'Facebook Lead Ads',
        value: 6
      },
      {
        label: 'Manual',
        value: 7
      },
      {
        label: 'Direct Traffic',
        value: 8
      },
      {
        label: 'SMS',
        value: 9
      },
      {
        label: 'Zapier',
        value: 10
      },
      {
        label: 'Whatsapp',
        value: 11
      },
      {
        label: 'CareStack',
        value: 12
      },
      {
        label: 'CS Phones',
        value: 13
      }
    ],
    CSPhones: 13,
    CareStack: 12,
    DirectTraffic: 8,
    Facebook: 1,
    FacebookLeadAds: 6,
    Google: 2,
    GoogleMyBusiness: 3,
    Instagram: 4,
    Manual: 7,
    SMS: 9,
    Twitter: 5,
    Zapier: 10,
    whatsapp: 11
  },
  AutomationChoice: {
    ASSIGN_MEMBER: 2,
    AUTORESPONDER_EMAIL: 1,
    CHOICES: {
      1: 'AUTORESPONDER_EMAIL',
      2: 'ASSIGN_MEMBER',
      3: 'MOVE_TO_BOX',
      4: 'FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER',
      5: 'DELAY_TIMER',
      6: 'SMS',
      7: 'WHATSAPP'
    },
    CHOICE_LIST: [
      {
        label: 'AUTORESPONDER_EMAIL',
        value: 1
      },
      {
        label: 'ASSIGN_MEMBER',
        value: 2
      },
      {
        label: 'MOVE_TO_BOX',
        value: 3
      },
      {
        label: 'FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER',
        value: 4
      },
      {
        label: 'DELAY_TIMER',
        value: 5
      },
      {
        label: 'SMS',
        value: 6
      },
      {
        label: 'WHATSAPP',
        value: 7
      }
    ],
    DELAY_TIMER: 5,
    FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER: 4,
    MOVE_TO_BOX: 3,
    SMS: 6,
    WHATSAPP: 7
  },
  AvailablePhoneNumberChoices: {
    CHOICES: {
      1: 'Pending',
      2: 'Success',
      3: 'failure'
    },
    CHOICE_LIST: [
      {
        label: 'Pending',
        value: 1
      },
      {
        label: 'Success',
        value: 2
      },
      {
        label: 'failure',
        value: 3
      }
    ],
    FAILURE: 3,
    PENDING: 1,
    SUCCESS: 2
  },
  BotPlacement: {
    CENTER: 3,
    CHOICES: {
      1: 'Left',
      2: 'Right',
      3: 'Center'
    },
    CHOICE_LIST: [
      {
        label: 'Left',
        value: 1
      },
      {
        label: 'Right',
        value: 2
      },
      {
        label: 'Center',
        value: 3
      }
    ],
    LEFT: 1,
    RIGHT: 2
  },
  BoxAutomationChoice: {
    ASSIGN_MEMBER: 2,
    AUTORESPONDER_EMAIL: 1,
    CHOICES: {
      1: 'AUTORESPONDER_EMAIL',
      2: 'ASSIGN_MEMBER',
      3: 'MOVE_TO_BOX',
      4: 'FACEBOOK',
      5: 'DELAY_TIMER',
      6: 'INSTAGRAM',
      7: 'SMS',
      8: 'NOTES',
      9: 'TASK',
      10: 'WHATSAPP'
    },
    CHOICE_LIST: [
      {
        label: 'AUTORESPONDER_EMAIL',
        value: 1
      },
      {
        label: 'ASSIGN_MEMBER',
        value: 2
      },
      {
        label: 'MOVE_TO_BOX',
        value: 3
      },
      {
        label: 'FACEBOOK',
        value: 4
      },
      {
        label: 'DELAY_TIMER',
        value: 5
      },
      {
        label: 'INSTAGRAM',
        value: 6
      },
      {
        label: 'SMS',
        value: 7
      },
      {
        label: 'NOTES',
        value: 8
      },
      {
        label: 'TASK',
        value: 9
      },
      {
        label: 'WHATSAPP',
        value: 10
      }
    ],
    DELAY_TIMER: 5,
    FACEBOOK: 4,
    INSTAGRAM: 6,
    MOVE_TO_BOX: 3,
    NOTES: 8,
    SMS: 7,
    TASK: 9,
    WHATSAPP: 10
  },
  BoxType: {
    AI: 1,
    CHOICES: {
      1: 'AI',
      2: 'General',
      3: 'Custom',
      4: 'New Incoming Leads'
    },
    CHOICE_LIST: [
      {
        label: 'AI',
        value: 1
      },
      {
        label: 'General',
        value: 2
      },
      {
        label: 'Custom',
        value: 3
      },
      {
        label: 'New Incoming Leads',
        value: 4
      }
    ],
    CUSTOM: 3,
    GENERAL: 2,
    NEW_INCOMING_LEADS: 4
  },
  ChannelChoices: {
    CARE_STACK: 13,
    CHOICES: {
      1: 'Facebook',
      2: 'Instagram',
      3: 'WhatsApp',
      4: 'Webform',
      5: 'Facebook lead ads',
      6: 'Zapier',
      7: 'Sms',
      8: 'Ebot',
      9: 'Google business reviews',
      10: 'Website chat bot',
      11: 'Email',
      12: 'Webforms',
      13: 'Care Stack',
      14: 'CS Phones'
    },
    CHOICE_LIST: [
      {
        label: 'Facebook',
        value: 1
      },
      {
        label: 'Instagram',
        value: 2
      },
      {
        label: 'WhatsApp',
        value: 3
      },
      {
        label: 'Webform',
        value: 4
      },
      {
        label: 'Facebook lead ads',
        value: 5
      },
      {
        label: 'Zapier',
        value: 6
      },
      {
        label: 'Sms',
        value: 7
      },
      {
        label: 'Ebot',
        value: 8
      },
      {
        label: 'Google business reviews',
        value: 9
      },
      {
        label: 'Website chat bot',
        value: 10
      },
      {
        label: 'Email',
        value: 11
      },
      {
        label: 'Webforms',
        value: 12
      },
      {
        label: 'Care Stack',
        value: 13
      },
      {
        label: 'CS Phones',
        value: 14
      }
    ],
    CS_PHONES: 14,
    EBOT: 8,
    EMAIL_FORWARDING: 11,
    FACEBOOK: 1,
    FACEBOOK_LEAD_ADS: 5,
    GOOGLE_BUSINESS_REVIEWS: 9,
    INSTAGRAM: 2,
    SMS: 7,
    WEBFORM: 4,
    WEBFORM_FORWARDING: 12,
    WEBSITE_CHAT_BOT: 10,
    WHATSAPP: 3,
    ZAPIER: 6
  },
  CrmBuildStageChoices: {
    AI_WEBSITE_SUMMARY: 1,
    BOX_GENERATION: 3,
    CHOICES: {
      0: 'Initial Setup',
      1: 'AI Website Summary',
      2: 'Label Generation',
      3: 'Box Generation',
      4: 'Other Mandatory Stages',
      5: 'Postmark Setup',
      6: 'Xero Setup',
      7: 'Location Label Setup',
      8: 'Other Stages'
    },
    CHOICE_LIST: [
      {
        label: 'Initial Setup',
        value: 0
      },
      {
        label: 'AI Website Summary',
        value: 1
      },
      {
        label: 'Label Generation',
        value: 2
      },
      {
        label: 'Box Generation',
        value: 3
      },
      {
        label: 'Other Mandatory Stages',
        value: 4
      },
      {
        label: 'Postmark Setup',
        value: 5
      },
      {
        label: 'Xero Setup',
        value: 6
      },
      {
        label: 'Location Label Setup',
        value: 7
      },
      {
        label: 'Other Stages',
        value: 8
      }
    ],
    INITIAL_SETUP: 0,
    LABEL_GENERATION: 2,
    LOCATION_LABEL_SETUP: 7,
    OTHER_MANDATORY_STAGES: 4,
    OTHER_STAGES: 8,
    POSTMARK_SETUP: 5,
    XERO_SETUP: 6
  },
  CrmBuildStageStatus: {
    CHOICES: {
      1: 'Started',
      2: 'Completed',
      3: 'Failed'
    },
    CHOICE_LIST: [
      {
        label: 'Started',
        value: 1
      },
      {
        label: 'Completed',
        value: 2
      },
      {
        label: 'Failed',
        value: 3
      }
    ],
    COMPLETED: 2,
    FAILED: 3,
    STARTED: 1
  },
  CrmBuildingStatusChoices: {
    CHOICES: {
      1: 'Not required',
      2: 'Website details still not submitted',
      3: 'Started',
      4: 'Completed',
      5: 'Failed'
    },
    CHOICE_LIST: [
      {
        label: 'Not required',
        value: 1
      },
      {
        label: 'Website details still not submitted',
        value: 2
      },
      {
        label: 'Started',
        value: 3
      },
      {
        label: 'Completed',
        value: 4
      },
      {
        label: 'Failed',
        value: 5
      }
    ],
    COMPLETED: 4,
    FAILED: 5,
    NOT_REQUIRED: 1,
    STARTED: 3,
    WEBSITE_DETAILS_STILL_NOT_SUBMITTED: 2
  },
  CrmBuiltTypeChoices: {
    CHOICES: {
      1: 'from wordpress',
      2: 'from with website',
      3: 'from without website',
      4: 'from multi sub org website'
    },
    CHOICE_LIST: [
      {
        label: 'from wordpress',
        value: 1
      },
      {
        label: 'from with website',
        value: 2
      },
      {
        label: 'from without website',
        value: 3
      },
      {
        label: 'from multi sub org website',
        value: 4
      }
    ],
    FROM_MULTI_SUB_ORG_WEBSITE: 4,
    FROM_WITHOUT_WEBSITE: 3,
    FROM_WITH_WEBSITE: 2,
    FROM_WORDPRESS: 1
  },
  CurrencyChoice: {
    AUD: 4,
    CHOICES: {
      1: 'GBP',
      2: 'EUR',
      3: 'USD',
      4: 'AUD'
    },
    CHOICE_LIST: [
      {
        label: 'GBP',
        value: 1
      },
      {
        label: 'EUR',
        value: 2
      },
      {
        label: 'USD',
        value: 3
      },
      {
        label: 'AUD',
        value: 4
      }
    ],
    EUR: 2,
    GBP: 1,
    USD: 3
  },
  CurrencyChoices: {
    CHOICES: {
      1: 'GBP',
      2: 'USD',
      3: 'EURO'
    },
    CHOICE_LIST: [
      {
        label: 'GBP',
        value: 1
      },
      {
        label: 'USD',
        value: 2
      },
      {
        label: 'EURO',
        value: 3
      }
    ],
    EURO: 3,
    GBP: 1,
    USD: 2
  },
  DelayChoices: {
    CHOICES: {
      1: 'Minutes',
      2: 'Days',
      3: 'Hours'
    },
    CHOICE_LIST: [
      {
        label: 'Minutes',
        value: 1
      },
      {
        label: 'Days',
        value: 2
      },
      {
        label: 'Hours',
        value: 3
      }
    ],
    DAYS: 2,
    HOURS: 3,
    MINUTE: 1
  },
  DeviceChoices: {
    ANDROID: 2,
    CHOICES: {
      1: 'Ios',
      2: 'Android',
      3: 'Web'
    },
    CHOICE_LIST: [
      {
        label: 'Ios',
        value: 1
      },
      {
        label: 'Android',
        value: 2
      },
      {
        label: 'Web',
        value: 3
      }
    ],
    IOS: 1,
    WEB: 3
  },
  DummyLeadChoices: {
    CHOICES: {
      1: 'Customise your Lead Cards',
      2: 'Stage based Automation Sequences',
      3: 'Track conversions with stages',
      4: 'Adding Lead with a Lead Form',
      5: 'Using Smart Rules to save time',
      6: 'Using stages to track leads',
      7: 'Getting the right leads in this Box'
    },
    CHOICE_LIST: [
      {
        label: 'Customise your Lead Cards',
        value: 1
      },
      {
        label: 'Stage based Automation Sequences',
        value: 2
      },
      {
        label: 'Track conversions with stages',
        value: 3
      },
      {
        label: 'Adding Lead with a Lead Form',
        value: 4
      },
      {
        label: 'Using Smart Rules to save time',
        value: 5
      },
      {
        label: 'Using stages to track leads',
        value: 6
      },
      {
        label: 'Getting the right leads in this Box',
        value: 7
      }
    ],
    DUMMY_AUTOMATED_SEQUENCES: 2,
    DUMMY_BOX_STAGES: 6,
    DUMMY_CUSTOM_LEAD_CARDS: 1,
    DUMMY_CUSTOM_LEAD_FORM: 4,
    DUMMY_RIGHT_LEADS: 7,
    DUMMY_SMART_RULES: 5,
    DUMMY_TRACK_CONVERSIONS: 3
  },
  EBotStatus: {
    ARCHIVED: 5,
    CHOICES: {
      1: 'Draft',
      2: 'Review',
      3: 'Declined',
      4: 'Published',
      5: 'Archived',
      6: 'Deleted'
    },
    CHOICE_LIST: [
      {
        label: 'Draft',
        value: 1
      },
      {
        label: 'Review',
        value: 2
      },
      {
        label: 'Declined',
        value: 3
      },
      {
        label: 'Published',
        value: 4
      },
      {
        label: 'Archived',
        value: 5
      },
      {
        label: 'Deleted',
        value: 6
      }
    ],
    DECLINED: 3,
    DELETED: 6,
    DRAFT: 1,
    PUBLISHED: 4,
    REVIEW: 2
  },
  EmailForwardingStatusChoice: {
    CHOICES: {
      1: 'Pending',
      2: 'Not forwarding',
      3: 'Forwarding'
    },
    CHOICE_LIST: [
      {
        label: 'Pending',
        value: 1
      },
      {
        label: 'Not forwarding',
        value: 2
      },
      {
        label: 'Forwarding',
        value: 3
      }
    ],
    FORWARDING: 3,
    NOT_FORWARDING: 2,
    PENDING: 1
  },
  EmailSenderChoice: {
    CHOICES: {
      1: 'Verified',
      2: 'Pending'
    },
    CHOICE_LIST: [
      {
        label: 'Verified',
        value: 1
      },
      {
        label: 'Pending',
        value: 2
      }
    ],
    PENDING: 2,
    VERIFIED: 1
  },
  EmailSignatureChoice: {
    CHOICES: {
      1: 'Default',
      2: 'Custom'
    },
    CHOICE_LIST: [
      {
        label: 'Default',
        value: 1
      },
      {
        label: 'Custom',
        value: 2
      }
    ],
    CUSTOM: 2,
    DEFAULT: 1
  },
  EmailType: {
    ACCOUNT_CONFIRMATION_EMAIL: 3,
    CHOICES: {
      1: 'LEAD_REPLY',
      2: 'INVITE_EMAIL',
      3: 'ACCOUNT_CONFIRMATION_EMAIL',
      4: 'FORGOT_EMAIL',
      5: 'CSV_SEND',
      6: 'FACEBOOK_INCOMING_MESSAGE',
      7: 'NEW_FACEBOOK_LEAD_ADS_ENQUIRY_ARRIVED',
      8: 'SENDING_TASK_DUE_EMAIL_REMINDER',
      9: 'NEW_WEBFORM_ENQUIRY_ARRIVED',
      10: 'NEW_SMS_ENQUIRY_ARRIVED',
      11: 'NEW_BOT_ENQUIRY_ARRIVED',
      12: 'NEW_ZAPIER_ENQUIRY_ARRIVED',
      13: 'SIMPLE_MAIL_FROM_TO_USER',
      14: 'NEW_WHATSAPP_ENQUIRY_ARRIVED',
      15: 'SENDER_OTP_CONFIRMATION_EMAIL',
      16: 'DOMAIN_VERIFIED_EMAIL',
      17: 'USER_TAGGED_IN_NOTE',
      18: 'USER_ASSIGNED_TO_LEAD',
      19: 'NEW_WEBFORM_FORWARDING_ENQUIRY_ARRIVED',
      20: 'NEW_CARESTACK_ENQUIRY_ARRIVED',
      21: 'NEW_CARESTACK_ENQUIRY_ARRIVED'
    },
    CHOICE_LIST: [
      {
        label: 'LEAD_REPLY',
        value: 1
      },
      {
        label: 'INVITE_EMAIL',
        value: 2
      },
      {
        label: 'ACCOUNT_CONFIRMATION_EMAIL',
        value: 3
      },
      {
        label: 'FORGOT_EMAIL',
        value: 4
      },
      {
        label: 'CSV_SEND',
        value: 5
      },
      {
        label: 'FACEBOOK_INCOMING_MESSAGE',
        value: 6
      },
      {
        label: 'NEW_FACEBOOK_LEAD_ADS_ENQUIRY_ARRIVED',
        value: 7
      },
      {
        label: 'SENDING_TASK_DUE_EMAIL_REMINDER',
        value: 8
      },
      {
        label: 'NEW_WEBFORM_ENQUIRY_ARRIVED',
        value: 9
      },
      {
        label: 'NEW_SMS_ENQUIRY_ARRIVED',
        value: 10
      },
      {
        label: 'NEW_BOT_ENQUIRY_ARRIVED',
        value: 11
      },
      {
        label: 'NEW_ZAPIER_ENQUIRY_ARRIVED',
        value: 12
      },
      {
        label: 'SIMPLE_MAIL_FROM_TO_USER',
        value: 13
      },
      {
        label: 'NEW_WHATSAPP_ENQUIRY_ARRIVED',
        value: 14
      },
      {
        label: 'SENDER_OTP_CONFIRMATION_EMAIL',
        value: 15
      },
      {
        label: 'DOMAIN_VERIFIED_EMAIL',
        value: 16
      },
      {
        label: 'USER_TAGGED_IN_NOTE',
        value: 17
      },
      {
        label: 'USER_ASSIGNED_TO_LEAD',
        value: 18
      },
      {
        label: 'NEW_WEBFORM_FORWARDING_ENQUIRY_ARRIVED',
        value: 19
      },
      {
        label: 'NEW_CARESTACK_ENQUIRY_ARRIVED',
        value: 20
      },
      {
        label: 'NEW_CARESTACK_ENQUIRY_ARRIVED',
        value: 21
      }
    ],
    CSV_SEND: 5,
    DOMAIN_VERIFIED_EMAIL: 16,
    FACEBOOK_INCOMING_MESSAGE: 6,
    FORGOT_EMAIL: 4,
    INVITE_EMAIL: 2,
    LEAD_REPLY: 1,
    NEW_BOT_ENQUIRY_ARRIVED: 11,
    NEW_CARESTACK_ENQUIRY_ARRIVED: 20,
    NEW_CS_PHONES_ENQUIRY_ARRIVED: 21,
    NEW_FACEBOOK_LEAD_ADS_ENQUIRY_ARRIVED: 7,
    NEW_SMS_ENQUIRY_ARRIVED: 10,
    NEW_WEBFORM_ENQUIRY_ARRIVED: 9,
    NEW_WEBFORM_FORWARDING_ENQUIRY_ARRIVED: 19,
    NEW_WHATSAPP_ENQUIRY_ARRIVED: 14,
    NEW_ZAPIER_ENQUIRY_ARRIVED: 12,
    SENDER_OTP_CONFIRMATION_EMAIL: 15,
    SENDING_TASK_DUE_EMAIL_REMINDER: 8,
    SIMPLE_MAIL_FROM_TO_USER: 13,
    USER_ASSIGNED_TO_LEAD: 18,
    USER_TAGGED_IN_NOTE: 17
  },
  EmailVeririfedThroughChoice: {
    CHOICES: {
      1: 'Ses',
      2: 'Google',
      3: 'Microsoft'
    },
    CHOICE_LIST: [
      {
        label: 'Ses',
        value: 1
      },
      {
        label: 'Google',
        value: 2
      },
      {
        label: 'Microsoft',
        value: 3
      }
    ],
    GOOGLE: 2,
    MICROSOFT: 3,
    SES: 1
  },
  FbAttachmentTypeChoices: {
    AUDIO: 'audio',
    CHOICES: {
      'application/pdf': 'Pdf',
      audio: 'Audio',
      file: 'File',
      gif: 'Gif',
      image: 'Image',
      'image/gif': 'Gif',
      'image/jpeg': 'Image',
      'text/csv': 'Csv',
      video: 'Video',
      'video/mp4': 'Video'
    },
    CHOICE_LIST: [
      {
        label: 'Pdf',
        value: 'application/pdf'
      },
      {
        label: 'Audio',
        value: 'audio'
      },
      {
        label: 'File',
        value: 'file'
      },
      {
        label: 'Gif',
        value: 'gif'
      },
      {
        label: 'Image',
        value: 'image'
      },
      {
        label: 'Gif',
        value: 'image/gif'
      },
      {
        label: 'Image',
        value: 'image/jpeg'
      },
      {
        label: 'Csv',
        value: 'text/csv'
      },
      {
        label: 'Video',
        value: 'video'
      },
      {
        label: 'Video',
        value: 'video/mp4'
      }
    ],
    FILE: 'file',
    FILE_1: 'application/pdf',
    FILE_2: 'text/csv',
    GIF: 'gif',
    GIF_1: 'image/gif',
    IMAGE: 'image',
    IMAGE_1: 'image/jpeg',
    VIDEO: 'video',
    VIDEO_1: 'video/mp4'
  },
  FbPermissionStatusChoices: {
    CHOICES: {
      declined: 'Declined',
      expired: 'Expired',
      granted: 'Granted'
    },
    CHOICE_LIST: [
      {
        label: 'Declined',
        value: 'declined'
      },
      {
        label: 'Expired',
        value: 'expired'
      },
      {
        label: 'Granted',
        value: 'granted'
      }
    ],
    DECLINED: 'declined',
    EXPIRED: 'expired',
    GRANTED: 'granted'
  },
  FileUploadingChoices: {
    CHOICES: {
      1: 'Profile photo',
      2: 'File library',
      3: 'Email outgoing',
      4: 'Email incoming',
      5: 'Webform',
      6: 'Facebook incoming',
      7: 'Lead import from csv',
      8: 'Instagram story',
      9: 'zapier_files',
      10: 'Whatsapp_files'
    },
    CHOICE_LIST: [
      {
        label: 'Profile photo',
        value: 1
      },
      {
        label: 'File library',
        value: 2
      },
      {
        label: 'Email outgoing',
        value: 3
      },
      {
        label: 'Email incoming',
        value: 4
      },
      {
        label: 'Webform',
        value: 5
      },
      {
        label: 'Facebook incoming',
        value: 6
      },
      {
        label: 'Lead import from csv',
        value: 7
      },
      {
        label: 'Instagram story',
        value: 8
      },
      {
        label: 'zapier_files',
        value: 9
      },
      {
        label: 'Whatsapp_files',
        value: 10
      }
    ],
    EMAIL_INCOMING: 4,
    EMAIL_OUTGOING: 3,
    FACEBOOK_INCOMING: 6,
    FILE_LIBRARY: 2,
    INSTAGRAM_STORY: 8,
    LEAD_IMPORT_FROM_CSV: 7,
    PROFILE_PHOTO: 1,
    WEBFORM: 5,
    WHATSAPP_FILES: 10,
    ZAPIER_FILES: 9
  },
  FollowUpStatusChoice: {
    AMBER: 2,
    CHOICES: {
      1: 'Green',
      2: 'Amber',
      3: 'Red',
      4: 'Grey'
    },
    CHOICE_LIST: [
      {
        label: 'Green',
        value: 1
      },
      {
        label: 'Amber',
        value: 2
      },
      {
        label: 'Red',
        value: 3
      },
      {
        label: 'Grey',
        value: 4
      }
    ],
    GREEN: 1,
    GREY: 4,
    RED: 3
  },
  FollowUpTimeChoice: {
    CHOICES: {
      1: 'Minutes',
      2: 'Hours'
    },
    CHOICE_LIST: [
      {
        label: 'Minutes',
        value: 1
      },
      {
        label: 'Hours',
        value: 2
      }
    ],
    HOURS: 2,
    MINUTES: 1
  },
  FrontendRedirectChoice: {
    BUSINESS_INFO: 2,
    CHOICES: {
      1: 'Dashboard',
      2: 'Business info',
      3: 'Login screen',
      4: 'Create new password',
      5: 'Email resend screen',
      6: 'Crm loading screen',
      7: 'Crm summary failed screen',
      8: 'Stripe Checkout Screen',
      9: 'Multi sub org screen'
    },
    CHOICE_LIST: [
      {
        label: 'Dashboard',
        value: 1
      },
      {
        label: 'Business info',
        value: 2
      },
      {
        label: 'Login screen',
        value: 3
      },
      {
        label: 'Create new password',
        value: 4
      },
      {
        label: 'Email resend screen',
        value: 5
      },
      {
        label: 'Crm loading screen',
        value: 6
      },
      {
        label: 'Crm summary failed screen',
        value: 7
      },
      {
        label: 'Stripe Checkout Screen',
        value: 8
      },
      {
        label: 'Multi sub org screen',
        value: 9
      }
    ],
    CREATE_NEW_PASSWORD_SCREEN: 4,
    CRM_LOADING_SCREEN: 6,
    CRM_SUMMARY_FAILED_SCREEN: 7,
    DASHBOARD: 1,
    EMAIL_RESEND_SCREEN: 5,
    LOGIN_SCREEN: 3,
    MULTI_SUB_ORG_SCREEN: 9,
    STRIPE_CHECKOUT_SCREEN: 8
  },
  IndustryChoices: {
    CHOICES: {
      1: 'Dental',
      2: 'Golf',
      3: 'Leisure',
      4: 'Hotel',
      5: 'Wedding',
      6: 'Cosmetic & Aesthetic',
      7: 'IT Services',
      8: 'Other'
    },
    CHOICE_LIST: [
      {
        label: 'Dental',
        value: 1
      },
      {
        label: 'Golf',
        value: 2
      },
      {
        label: 'Leisure',
        value: 3
      },
      {
        label: 'Hotel',
        value: 4
      },
      {
        label: 'Wedding',
        value: 5
      },
      {
        label: 'Cosmetic & Aesthetic',
        value: 6
      },
      {
        label: 'IT Services',
        value: 7
      },
      {
        label: 'Other',
        value: 8
      }
    ],
    COSMETIC_AND_AESTHETIC: 6,
    DENTAL: 1,
    GOLF: 2,
    HOTEL: 4,
    IT_SERVICES: 7,
    LEISURE: 3,
    OTHER: 8,
    WEDDING: 5
  },
  LableCount: {
    CHOICES: {
      1: 'ONE_LABLE',
      2: 'TWO_LABLES'
    },
    CHOICE_LIST: [
      {
        label: 'ONE_LABLE',
        value: 1
      },
      {
        label: 'TWO_LABLES',
        value: 2
      }
    ],
    ONE_LABLE: 1,
    TWO_LABLE: 2
  },
  LeadChannelChoice: {
    CARE_STACK: 15,
    CHOICES: {
      1: 'Facebook',
      2: 'Instagram',
      3: 'Manually added',
      4: 'Web Forms',
      5: 'Facebook Lead Ads',
      6: 'Imported from file',
      7: 'SMS',
      8: 'Zapier',
      9: 'Ebot',
      10: 'Whatsapp',
      11: 'Website chat bot',
      12: 'Email forwarding',
      13: 'Dummy box lead',
      14: 'Webform Forwarding',
      15: 'Care Stack',
      16: 'CS Phones'
    },
    CHOICE_LIST: [
      {
        label: 'Facebook',
        value: 1
      },
      {
        label: 'Instagram',
        value: 2
      },
      {
        label: 'Manually added',
        value: 3
      },
      {
        label: 'Web Forms',
        value: 4
      },
      {
        label: 'Facebook Lead Ads',
        value: 5
      },
      {
        label: 'Imported from file',
        value: 6
      },
      {
        label: 'SMS',
        value: 7
      },
      {
        label: 'Zapier',
        value: 8
      },
      {
        label: 'Ebot',
        value: 9
      },
      {
        label: 'Whatsapp',
        value: 10
      },
      {
        label: 'Website chat bot',
        value: 11
      },
      {
        label: 'Email forwarding',
        value: 12
      },
      {
        label: 'Dummy box lead',
        value: 13
      },
      {
        label: 'Webform Forwarding',
        value: 14
      },
      {
        label: 'Care Stack',
        value: 15
      },
      {
        label: 'CS Phones',
        value: 16
      }
    ],
    CS_PHONES: 16,
    DUMMY_BOX_LEAD: 13,
    EBOT: 9,
    EMAIL_FORWARDING: 12,
    FACEBOOK: 1,
    FACEBOOK_LEADGEN: 5,
    IMPORTED_FROM_FILE: 6,
    INSTAGRAM: 2,
    MANUALLY_ADDED: 3,
    SMS: 7,
    WEBFORM_FORWARDING: 14,
    WEBSITE_CHAT_BOT: 11,
    WEB_FORMS: 4,
    WHATSAPP: 10,
    ZAPIER: 8
  },
  LeadReplyChoice: {
    CHOICES: {
      1: 'Normal reply',
      2: 'Note Reply',
      3: 'Just to store data',
      4: 'task reply',
      5: 'Email Reply',
      6: 'SMS Reply',
      7: 'Ig story mention',
      8: 'Ig story reply',
      9: 'Whatsapp reply',
      10: 'Whatsapp default reply',
      11: 'Care Stack reply',
      12: 'CS Phones reply',
      13: 'CS Message reply'
    },
    CHOICE_LIST: [
      {
        label: 'Normal reply',
        value: 1
      },
      {
        label: 'Note Reply',
        value: 2
      },
      {
        label: 'Just to store data',
        value: 3
      },
      {
        label: 'task reply',
        value: 4
      },
      {
        label: 'Email Reply',
        value: 5
      },
      {
        label: 'SMS Reply',
        value: 6
      },
      {
        label: 'Ig story mention',
        value: 7
      },
      {
        label: 'Ig story reply',
        value: 8
      },
      {
        label: 'Whatsapp reply',
        value: 9
      },
      {
        label: 'Whatsapp default reply',
        value: 10
      },
      {
        label: 'Care Stack reply',
        value: 11
      },
      {
        label: 'CS Phones reply',
        value: 12
      },
      {
        label: 'CS Message reply',
        value: 13
      }
    ],
    CS_MESSAGE_REPLY: 13,
    CS_PHONES: 12,
    CS_REPLY: 11,
    EMAIL_REPLY: 5,
    IG_STORY_MENTION: 7,
    IG_STORY_REPLY: 8,
    JUST_TO_STORE_DATA: 3,
    NORMAL_REPLY: 1,
    NOTE_REPLY: 2,
    SMS_REPLY: 6,
    TASK_REPLY: 4,
    WHATSAPP_DEFAULT_REPLY: 10,
    WHATSAPP_REPLY: 9
  },
  LeadSourceTypes: {
    CHOICES: {
      1: 'Paid',
      2: 'Unpaid'
    },
    CHOICE_LIST: [
      {
        label: 'Paid',
        value: 1
      },
      {
        label: 'Unpaid',
        value: 2
      }
    ],
    PAID: 1,
    UNPAID: 2
  },
  LeadStatusChoices: {
    CHOICES: {
      1: 'New',
      2: 'Viewed',
      3: 'Reply Later',
      4: 'Moved to Bin',
      5: 'RESTORED',
      6: 'Reply sent',
      7: 'Task added',
      8: 'Manually added',
      9: 'Note added',
      10: 'Move to box',
      11: 'Initially Unsorted'
    },
    CHOICE_LIST: [
      {
        label: 'New',
        value: 1
      },
      {
        label: 'Viewed',
        value: 2
      },
      {
        label: 'Reply Later',
        value: 3
      },
      {
        label: 'Moved to Bin',
        value: 4
      },
      {
        label: 'RESTORED',
        value: 5
      },
      {
        label: 'Reply sent',
        value: 6
      },
      {
        label: 'Task added',
        value: 7
      },
      {
        label: 'Manually added',
        value: 8
      },
      {
        label: 'Note added',
        value: 9
      },
      {
        label: 'Move to box',
        value: 10
      },
      {
        label: 'Initially Unsorted',
        value: 11
      }
    ],
    INITIALLY_UNSORTED: 11,
    MANUALLY_ADDED: 8,
    MOVED_TO_BIN: 4,
    MOVE_TO_BOX: 10,
    NEW: 1,
    NOTE_ADDED: 9,
    REPLY_LATER: 3,
    REPLY_SENT: 6,
    RESTORED: 5,
    TASK_ADDED: 7,
    VIEWED: 2
  },
  MessageStatus: {
    CHOICES: {
      1: 'Sent',
      2: 'Queued',
      3: 'Delivered',
      4: 'FAILED',
      5: 'Seen',
      6: 'Sending',
      7: 'Delivery unconfirmed',
      8: 'Clicked'
    },
    CHOICE_LIST: [
      {
        label: 'Sent',
        value: 1
      },
      {
        label: 'Queued',
        value: 2
      },
      {
        label: 'Delivered',
        value: 3
      },
      {
        label: 'FAILED',
        value: 4
      },
      {
        label: 'Seen',
        value: 5
      },
      {
        label: 'Sending',
        value: 6
      },
      {
        label: 'Delivery unconfirmed',
        value: 7
      },
      {
        label: 'Clicked',
        value: 8
      }
    ],
    DELIVERED: 3,
    DELIVERY_UNCONFIRMED: 7,
    FAILED: 4,
    LINK_CLICKED: 8,
    QUEUED: 2,
    SEEN: 5,
    SENDING: 6,
    SENT: 1
  },
  NewIndustryChoices: {
    ACCESS_AND_TOOL_HIRE: 4,
    CHOICES: {
      1: 'Dental',
      2: 'Cosmetic and Aesthetics',
      3: 'Golf and Leisure',
      4: 'Access and Tool Hire',
      5: 'Hotel',
      6: 'Hospitality',
      7: 'Gas Boiler and Heating',
      8: 'Wedding',
      9: 'Other'
    },
    CHOICE_LIST: [
      {
        label: 'Dental',
        value: 1
      },
      {
        label: 'Cosmetic and Aesthetics',
        value: 2
      },
      {
        label: 'Golf and Leisure',
        value: 3
      },
      {
        label: 'Access and Tool Hire',
        value: 4
      },
      {
        label: 'Hotel',
        value: 5
      },
      {
        label: 'Hospitality',
        value: 6
      },
      {
        label: 'Gas Boiler and Heating',
        value: 7
      },
      {
        label: 'Wedding',
        value: 8
      },
      {
        label: 'Other',
        value: 9
      }
    ],
    COSMETIC_AND_AESTHETICS: 2,
    DENTAL: 1,
    GAS_BOILER_AND_HEATING: 7,
    GOLF_AND_LEISURE: 3,
    HOSPITALITY: 6,
    HOTEL: 5,
    OTHER: 9,
    WEDDING: 8
  },
  OnboardingTeamMemberChoice: {
    ACCEPTED: 1,
    CHOICES: {
      1: 'Accepted',
      2: 'Pending',
      3: 'Default'
    },
    CHOICE_LIST: [
      {
        label: 'Accepted',
        value: 1
      },
      {
        label: 'Pending',
        value: 2
      },
      {
        label: 'Default',
        value: 3
      }
    ],
    DEFAULT: 3,
    PENDING: 2
  },
  OptionCategory: {
    BUTTON: 2,
    CHOICES: {
      0: 'Text',
      1: 'Image',
      2: 'Button',
      3: 'Others'
    },
    CHOICE_LIST: [
      {
        label: 'Text',
        value: 0
      },
      {
        label: 'Image',
        value: 1
      },
      {
        label: 'Button',
        value: 2
      },
      {
        label: 'Others',
        value: 3
      }
    ],
    IMAGE: 1,
    OTHERS: 3,
    TEXT: 0
  },
  OrganizationUserFilterChoice: {
    ALL: 1,
    CHOICES: {
      1: 'All',
      2: 'Signup',
      3: 'Not signup'
    },
    CHOICE_LIST: [
      {
        label: 'All',
        value: 1
      },
      {
        label: 'Signup',
        value: 2
      },
      {
        label: 'Not signup',
        value: 3
      }
    ],
    NOT_SIGNUP: 3,
    SIGNUP: 2
  },
  PlanType: {
    BOT: 4,
    CHOICES: {
      1: 'Free Trial',
      2: 'Starter',
      3: 'Pro',
      4: 'Bot as addon',
      5: 'Starter plus bot',
      6: 'Pro plus bot',
      7: 'Starter',
      8: 'Pro'
    },
    CHOICE_LIST: [
      {
        label: 'Free Trial',
        value: 1
      },
      {
        label: 'Starter',
        value: 2
      },
      {
        label: 'Pro',
        value: 3
      },
      {
        label: 'Bot as addon',
        value: 4
      },
      {
        label: 'Starter plus bot',
        value: 5
      },
      {
        label: 'Pro plus bot',
        value: 6
      },
      {
        label: 'Starter',
        value: 7
      },
      {
        label: 'Pro',
        value: 8
      }
    ],
    FREE_TRIAL: 1,
    NEW_PRO: 8,
    NEW_STARTER: 7,
    PRO: 3,
    PRO_BOT: 6,
    STARTER: 2,
    STARTER_BOT: 5
  },
  QuestionChoices: {
    CHOICES: {
      1: 'Text',
      2: 'Dropdown',
      3: 'Date',
      4: 'Multiple Options',
      5: 'Value',
      6: 'MCQ_MUTIPLE_SELECT',
      7: 'DATE_RANGE',
      8: 'Yes or No'
    },
    CHOICE_LIST: [
      {
        label: 'Text',
        value: 1
      },
      {
        label: 'Dropdown',
        value: 2
      },
      {
        label: 'Date',
        value: 3
      },
      {
        label: 'Multiple Options',
        value: 4
      },
      {
        label: 'Value',
        value: 5
      },
      {
        label: 'MCQ_MUTIPLE_SELECT',
        value: 6
      },
      {
        label: 'DATE_RANGE',
        value: 7
      },
      {
        label: 'Yes or No',
        value: 8
      }
    ],
    DATETIME: 3,
    DATE_RANGE: 7,
    DROPDOWN: 2,
    MCQ: 4,
    MCQ_MUTIPLE_SELECT: 6,
    TEXT: 1,
    VALUE: 5,
    YES_OR_NO: 8
  },
  QuestionType: {
    CHOICES: {
      1: 'Genders',
      2: 'DOB',
      3: 'Subscribe for'
    },
    CHOICE_LIST: [
      {
        label: 'Genders',
        value: 1
      },
      {
        label: 'DOB',
        value: 2
      },
      {
        label: 'Subscribe for',
        value: 3
      }
    ],
    DOB: 2,
    GENDER: 1,
    SUBSCRIBE_FOR: 3
  },
  ReplySource: {
    AUTOMATION: 1,
    CHOICES: {
      1: 'Automation',
      2: 'Email'
    },
    CHOICE_LIST: [
      {
        label: 'Automation',
        value: 1
      },
      {
        label: 'Email',
        value: 2
      }
    ],
    EMAIL: 2
  },
  ReplyTemplateChoice: {
    CHOICES: {
      1: 'Email',
      2: 'SMS',
      3: 'Facebook and Instagram',
      4: 'Whatsapp'
    },
    CHOICE_LIST: [
      {
        label: 'Email',
        value: 1
      },
      {
        label: 'SMS',
        value: 2
      },
      {
        label: 'Facebook and Instagram',
        value: 3
      },
      {
        label: 'Whatsapp',
        value: 4
      }
    ],
    EMAIL: 1,
    FACEBOOK_AND_INSTAGRAM: 3,
    SMS: 2,
    WHATSAPP: 4
  },
  ReportType: {
    CHOICES: {
      1: 'INCOMING_REPORT',
      2: 'SALES_REPORT',
      3: 'SOURCE_REPORT'
    },
    CHOICE_LIST: [
      {
        label: 'INCOMING_REPORT',
        value: 1
      },
      {
        label: 'SALES_REPORT',
        value: 2
      },
      {
        label: 'SOURCE_REPORT',
        value: 3
      }
    ],
    INCOMING_SALES_REPORT: 1,
    SALES_REPORT: 2,
    SOURCE_REPORT: 3
  },
  SEND_EMAIL: {
    CHOICES: {
      1: 'SES'
    },
    CHOICE_LIST: [
      {
        label: 'SES',
        value: 1
      }
    ],
    SES: 1
  },
  SignupChoice: {
    CHOICES: {
      1: 'Email',
      2: 'Google',
      3: 'microsoft'
    },
    CHOICE_LIST: [
      {
        label: 'Email',
        value: 1
      },
      {
        label: 'Google',
        value: 2
      },
      {
        label: 'microsoft',
        value: 3
      }
    ],
    EMAIL: 1,
    GOOGLE: 2,
    MICROSOFT: 3
  },
  StageKind: {
    CHOICES: {
      1: 'Incoming',
      2: 'Contacting',
      3: 'Converted Sale',
      4: 'Lost',
      5: 'Waiting on Decision',
      6: 'Custom',
      7: 'Suitable',
      8: 'Not Suitable',
      9: 'Contacted',
      10: 'Interview arranged',
      11: 'First Round',
      12: 'Second Round',
      13: 'Final Round',
      14: 'Dealing With',
      15: 'Completed',
      16: 'Waiting on Customer'
    },
    CHOICE_LIST: [
      {
        label: 'Incoming',
        value: 1
      },
      {
        label: 'Contacting',
        value: 2
      },
      {
        label: 'Converted Sale',
        value: 3
      },
      {
        label: 'Lost',
        value: 4
      },
      {
        label: 'Waiting on Decision',
        value: 5
      },
      {
        label: 'Custom',
        value: 6
      },
      {
        label: 'Suitable',
        value: 7
      },
      {
        label: 'Not Suitable',
        value: 8
      },
      {
        label: 'Contacted',
        value: 9
      },
      {
        label: 'Interview arranged',
        value: 10
      },
      {
        label: 'First Round',
        value: 11
      },
      {
        label: 'Second Round',
        value: 12
      },
      {
        label: 'Final Round',
        value: 13
      },
      {
        label: 'Dealing With',
        value: 14
      },
      {
        label: 'Completed',
        value: 15
      },
      {
        label: 'Waiting on Customer',
        value: 16
      }
    ],
    COMPLETED: 15,
    CONTACTED: 9,
    CONTACTING: 2,
    CONVERTED_SALE: 3,
    CUSTOM: 6,
    DEALING_WITH: 14,
    FINAL_ROUND: 13,
    FIRST_ROUND: 11,
    INCOMING: 1,
    INTERVIEW_ARRANGED: 10,
    LOST: 4,
    NOT_SUITABLE: 8,
    SECOND_ROUND: 12,
    SUITABLE: 7,
    WAITING_ON_CUSTOMER: 16,
    WAITING_ON_DECISION: 5
  },
  Status: {
    ANSWERED: 0,
    CHOICES: {
      0: 'Answered for inbound',
      1: 'Missed for inbound',
      2: 'Connected for outbound',
      3: 'Not connected for outbound'
    },
    CHOICE_LIST: [
      {
        label: 'Answered for inbound',
        value: 0
      },
      {
        label: 'Missed for inbound',
        value: 1
      },
      {
        label: 'Connected for outbound',
        value: 2
      },
      {
        label: 'Not connected for outbound',
        value: 3
      }
    ],
    CONNECTED: 2,
    MISSED: 1,
    NOTCONNECTED: 3
  },
  SubscriptionStatusChoices: {
    ACTIVE: 1,
    CANCELLED: 2,
    CHOICES: {
      1: 'Active',
      2: 'Cancelled',
      3: 'Pending'
    },
    CHOICE_LIST: [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Cancelled',
        value: 2
      },
      {
        label: 'Pending',
        value: 3
      }
    ],
    PENDING: 3
  },
  SystemNotificationTypeChoices: {
    CHOICES: {
      1: 'New Lead',
      2: 'Task Added',
      3: 'Task Reminder',
      4: 'Notes Added',
      5: 'Lead Assigned',
      6: 'Reply Received',
      7: 'Sign up invitation accepted'
    },
    CHOICE_LIST: [
      {
        label: 'New Lead',
        value: 1
      },
      {
        label: 'Task Added',
        value: 2
      },
      {
        label: 'Task Reminder',
        value: 3
      },
      {
        label: 'Notes Added',
        value: 4
      },
      {
        label: 'Lead Assigned',
        value: 5
      },
      {
        label: 'Reply Received',
        value: 6
      },
      {
        label: 'Sign up invitation accepted',
        value: 7
      }
    ],
    LEAD_ASSIGNED: 5,
    NEW_LEAD: 1,
    NOTES_ADDED: 4,
    REPLY_RECEIVED: 6,
    SIGN_UP_INVITATION_ACCEPTED: 7,
    TASK_ADDED: 2,
    TASK_REMINDER: 3
  },
  TaskDueChoices: {
    CHOICES: {
      1: 'Due Immediately',
      2: 'Due in days'
    },
    CHOICE_LIST: [
      {
        label: 'Due Immediately',
        value: 1
      },
      {
        label: 'Due in days',
        value: 2
      }
    ],
    DUE_IMMEDIATELY: 1,
    DUE_IN_DAYS: 2
  },
  TaskLeadApiFilterChoices: {
    BEYOND_7_DAYS: 'BEYOND_7_DAYS',
    CHOICES: {
      BEYOND_7_DAYS: 'BEYOND_7_DAYS',
      DUE_TODAY: 'DUE_TODAY',
      NEXT_7_DAYS: 'NEXT_7_DAYS',
      OVERDUE: 'OVERDUE'
    },
    CHOICE_LIST: [
      {
        label: 'BEYOND_7_DAYS',
        value: 'BEYOND_7_DAYS'
      },
      {
        label: 'DUE_TODAY',
        value: 'DUE_TODAY'
      },
      {
        label: 'NEXT_7_DAYS',
        value: 'NEXT_7_DAYS'
      },
      {
        label: 'OVERDUE',
        value: 'OVERDUE'
      }
    ],
    DUE_TODAY: 'DUE_TODAY',
    NEXT_7_DAYS: 'NEXT_7_DAYS',
    OVERDUE: 'OVERDUE'
  },
  TaskStatusChoices: {
    CHOICES: {
      1: 'New',
      2: 'Completed'
    },
    CHOICE_LIST: [
      {
        label: 'New',
        value: 1
      },
      {
        label: 'Completed',
        value: 2
      }
    ],
    COMPLETED: 2,
    NEW: 1
  },
  TasksTypeChoices: {
    CALL: 1,
    CHOICES: {
      1: 'Call',
      2: 'Follow up',
      3: 'Other',
      4: 'No Outcome'
    },
    CHOICE_LIST: [
      {
        label: 'Call',
        value: 1
      },
      {
        label: 'Follow up',
        value: 2
      },
      {
        label: 'Other',
        value: 3
      },
      {
        label: 'No Outcome',
        value: 4
      }
    ],
    FOLLOW_UP: 2,
    NO_OUTCOME: 4,
    OTHERS: 3
  },
  TeamMemberRoleChoice: {
    CHOICES: {
      1: 'Team admin',
      2: 'Team member',
      3: 'Team super admin',
      4: 'Organization admin',
      5: 'Group admin'
    },
    CHOICE_LIST: [
      {
        label: 'Team admin',
        value: 1
      },
      {
        label: 'Team member',
        value: 2
      },
      {
        label: 'Team super admin',
        value: 3
      },
      {
        label: 'Organization admin',
        value: 4
      },
      {
        label: 'Group admin',
        value: 5
      }
    ],
    GROUP_ADMIN: 5,
    ORG_ADMIN: 4,
    TEAM_ADMIN: 1,
    TEAM_MEMBER: 2,
    TEAM_SUPER_ADMIN: 3
  },
  TeamSizeChoice: {
    CHOICES: {
      1: '1-5 Employees',
      2: '6-10 Employees',
      3: '11-20 Employees',
      4: 'Greater than 20 Employees'
    },
    CHOICE_LIST: [
      {
        label: '1-5 Employees',
        value: 1
      },
      {
        label: '6-10 Employees',
        value: 2
      },
      {
        label: '11-20 Employees',
        value: 3
      },
      {
        label: 'Greater than 20 Employees',
        value: 4
      }
    ],
    FROM_11_20: 3,
    FROM_1_5: 1,
    FROM_20_100: 4,
    FROM_6_10: 2
  },
  TitleChoices: {
    APPOINTMENT_STATUS: 21,
    ASSIGNED: 5,
    AUTOMATED_REPLY_SENT: 7,
    CHOICES: {
      1: 'New Lead',
      2: 'Reply Received',
      3: 'Note Added',
      4: 'Reminder',
      5: 'Assigned',
      6: 'Moved To Box',
      7: 'Automated Reply Sent',
      8: 'Reply Sent',
      9: 'Moved To Bin',
      10: 'Deleted From Bin',
      11: 'Task Added',
      12: 'Task Completed',
      13: 'Label Added',
      14: 'Label Removed',
      15: 'Unassigned',
      16: 'Restored',
      17: 'Failure',
      18: 'Location Added',
      19: 'Location Removed',
      20: 'Contract Value Updated',
      21: 'Appointment Status Updated',
      22: 'Re Assigned',
      24: 'Source Updated',
      23: 'Practitioner Updated'
    },
    CHOICE_LIST: [
      {
        label: 'New Lead',
        value: 1
      },
      {
        label: 'Reply Received',
        value: 2
      },
      {
        label: 'Note Added',
        value: 3
      },
      {
        label: 'Reminder',
        value: 4
      },
      {
        label: 'Assigned',
        value: 5
      },
      {
        label: 'Moved To Box',
        value: 6
      },
      {
        label: 'Automated Reply Sent',
        value: 7
      },
      {
        label: 'Reply Sent',
        value: 8
      },
      {
        label: 'Moved To Bin',
        value: 9
      },
      {
        label: 'Deleted From Bin',
        value: 10
      },
      {
        label: 'Task Added',
        value: 11
      },
      {
        label: 'Task Completed',
        value: 12
      },
      {
        label: 'Label Added',
        value: 13
      },
      {
        label: 'Label Removed',
        value: 14
      },
      {
        label: 'Unassigned',
        value: 15
      },
      {
        label: 'Restored',
        value: 16
      },
      {
        label: 'Failure',
        value: 17
      },
      {
        label: 'Location Added',
        value: 18
      },
      {
        label: 'Location Removed',
        value: 19
      },
      {
        label: 'Contract Value Updated',
        value: 20
      },
      {
        label: 'Appointment Status Updated',
        value: 21
      },
      {
        label: 'Re Assigned',
        value: 22
      },
      {
        label: 'Source Updated',
        value: 24
      },
      {
        label: 'Practitioner Updated',
        value: 23
      }
    ],
    DELETED_FROM_BIN: 10,
    FAILURE: 17,
    LABEL_ADDED: 13,
    LABEL_REMOVED: 14,
    LOCATION_ADDED: 18,
    LOCATION_REMOVED: 19,
    MOVED_TO_BIN: 9,
    MOVED_TO_STAGE: 6,
    NEW_ENQUIRY: 1,
    NOTE_ADDED: 3,
    PRACTITIONER_UPDATED: 23,
    REMINDER: 4,
    REPLY_RECEIVED: 2,
    REPLY_SENT: 8,
    RESTORED: 16,
    RE_ASSIGNED: 22,
    SOURCE_UPDATED: 24,
    TASK_ADDED: 11,
    TASK_COMPLETED: 12,
    UNASSIGNED: 15,
    VALUE_UPDATED: 20
  },
  TypeOfMesChoices: {
    CHOICES: {
      1: 'New lead created',
      2: 'Mention in Lead',
      3: 'Lead Assigned',
      4: 'New reply from facebook',
      5: 'New reply from instagram',
      6: 'New reply from sms',
      7: 'New reply from email',
      8: 'Task assigned',
      9: 'Mentioned in the task',
      10: 'Mentioned in the task comment',
      11: 'Task reminder',
      12: 'Mentioned in the note has been updated',
      13: 'Mentioned in the note comment has been updated',
      17: 'New lead created from facebook',
      18: 'New lead created from instagram',
      19: 'New lead created from Web forms',
      20: 'New lead created from fb leadgen',
      21: 'Mentioned in the note',
      22: 'Webform lead is created',
      23: 'Zapier lead is created',
      24: 'New lead created from ebot connection',
      25: 'New lead created from sms ',
      26: 'New lead created from website chatbot',
      27: 'New lead created from whatsapp',
      28: 'New lead created from email forwarding',
      29: 'New reply from email forwarding',
      30: 'New lead created from webform forwarding',
      31: 'New lead created from CareStack ',
      32: 'New lead created from CS Phones '
    },
    CHOICE_LIST: [
      {
        label: 'New lead created',
        value: 1
      },
      {
        label: 'Mention in Lead',
        value: 2
      },
      {
        label: 'Lead Assigned',
        value: 3
      },
      {
        label: 'New reply from facebook',
        value: 4
      },
      {
        label: 'New reply from instagram',
        value: 5
      },
      {
        label: 'New reply from sms',
        value: 6
      },
      {
        label: 'New reply from email',
        value: 7
      },
      {
        label: 'Task assigned',
        value: 8
      },
      {
        label: 'Mentioned in the task',
        value: 9
      },
      {
        label: 'Mentioned in the task comment',
        value: 10
      },
      {
        label: 'Task reminder',
        value: 11
      },
      {
        label: 'Mentioned in the note has been updated',
        value: 12
      },
      {
        label: 'Mentioned in the note comment has been updated',
        value: 13
      },
      {
        label: 'New lead created from facebook',
        value: 17
      },
      {
        label: 'New lead created from instagram',
        value: 18
      },
      {
        label: 'New lead created from Web forms',
        value: 19
      },
      {
        label: 'New lead created from fb leadgen',
        value: 20
      },
      {
        label: 'Mentioned in the note',
        value: 21
      },
      {
        label: 'Webform lead is created',
        value: 22
      },
      {
        label: 'Zapier lead is created',
        value: 23
      },
      {
        label: 'New lead created from ebot connection',
        value: 24
      },
      {
        label: 'New lead created from sms ',
        value: 25
      },
      {
        label: 'New lead created from website chatbot',
        value: 26
      },
      {
        label: 'New lead created from whatsapp',
        value: 27
      },
      {
        label: 'New lead created from email forwarding',
        value: 28
      },
      {
        label: 'New reply from email forwarding',
        value: 29
      },
      {
        label: 'New lead created from webform forwarding',
        value: 30
      },
      {
        label: 'New lead created from CareStack ',
        value: 31
      },
      {
        label: 'New lead created from CS Phones ',
        value: 32
      }
    ],
    LEAD_ASSIGNED: 3,
    LEAD_CREATED: 1,
    LEAD_CREATED_FROM_CARESTACK: 31,
    LEAD_CREATED_FROM_CS_PHONES: 32,
    LEAD_CREATED_FROM_EBOT_CONNECTION: 24,
    LEAD_CREATED_FROM_EMAIL_FORWARDING: 28,
    LEAD_CREATED_FROM_FACEBOOK: 17,
    LEAD_CREATED_FROM_FB_LEADGEN: 20,
    LEAD_CREATED_FROM_FORM: 19,
    LEAD_CREATED_FROM_INSTAGRAM: 18,
    LEAD_CREATED_FROM_SMS: 25,
    LEAD_CREATED_FROM_WEBFORM: 22,
    LEAD_CREATED_FROM_WEBFORM_FORWARDING: 30,
    LEAD_CREATED_FROM_WEBSITE_CHATBOT: 26,
    LEAD_CREATED_FROM_WHATSAPP: 27,
    LEAD_CREATED_FROM_ZAPIER: 23,
    MENTIONED_IN_THE_NOTE: 21,
    MENTION_IN_LEAD: 2,
    NEW_REPLY_FROM_EMAIL: 7,
    NEW_REPLY_FROM_EMAIL_FORWARDING: 29,
    NEW_REPLY_FROM_FACEBOOK: 4,
    NEW_REPLY_FROM_INSTAGRAM: 5,
    NEW_REPLY_FROM_SMS: 6,
    NOTE_COMMENT_HAS_BEEN_MENTIONED_UPDATE: 13,
    NOTE_HAS_BEEN_MENTIONED_UPDATE: 12,
    TASK_ASSIGNED: 8,
    TASK_COMMENT_HAS_BEEN_MENTIONED: 10,
    TASK_HAS_BEEN_MENTIONED: 9,
    TASK_REMINDER: 11
  },
  UserTypeChoice: {
    ADMIN: 1,
    CHOICES: {
      1: 'Admin',
      2: 'Team Member',
      3: 'Team super admin',
      4: 'Organization Member',
      5: 'Group Admin'
    },
    CHOICE_LIST: [
      {
        label: 'Admin',
        value: 1
      },
      {
        label: 'Team Member',
        value: 2
      },
      {
        label: 'Team super admin',
        value: 3
      },
      {
        label: 'Organization Member',
        value: 4
      },
      {
        label: 'Group Admin',
        value: 5
      }
    ],
    GROUP_ADMIN: 5,
    ORG_ADMIN: 4,
    TEAM_MEMBER: 2,
    TEAM_SUPER_ADMIN: 3
  },
  VideoTypeChoices: {
    BOX_SETUP: 3,
    CHOICES: {
      1: 'Leads',
      2: 'Tasks',
      3: 'Box Setup',
      4: 'Smart Rule Info',
      5: 'Smart Rule Video',
      6: 'Webform Forwarding Video'
    },
    CHOICE_LIST: [
      {
        label: 'Leads',
        value: 1
      },
      {
        label: 'Tasks',
        value: 2
      },
      {
        label: 'Box Setup',
        value: 3
      },
      {
        label: 'Smart Rule Info',
        value: 4
      },
      {
        label: 'Smart Rule Video',
        value: 5
      },
      {
        label: 'Webform Forwarding Video',
        value: 6
      }
    ],
    LEADS: 1,
    SMART_RULE_INFO: 4,
    SMART_RULE_VIDEO: 5,
    TASKS: 2,
    WEBFORM_FORWARDING_VIDEO: 6
  }
};
export default CHOICES;
