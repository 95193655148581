import axios from 'axios';
import { toQueryString } from 'utils/toQueryString';
import swal from 'sweetalert';
import { BOT_ID, EBOT_EMAIL } from 'utils/constants';
import storageFactory, { getInterNetSpeedKey } from 'utils/localstorage';
import { calculateInternetSpeed } from './internetSpeed';

function getAuthToken() {
  if (window.__STORE__) {
    const { auth: { authToken, signUpAuthToken } = {} } =
      window.__STORE__.getState();
    return authToken || signUpAuthToken;
  }

  const store = JSON.parse(localStorage.getItem('persist:root'));
  const data = JSON.parse(store.auth) || {};
  return data.authToken || data.signUpAuthToken;
}

function getErrorResponse(response) {
  if (response?.Message) {
    const errorArray = Object.values(response?.ModelState);
    return errorArray[0].join(',');
  } else {
    return response;
  }
}

class APIRequest {
  getConfig(headers, options, params) {
    headers = {
      'Content-Type': 'application/json',
      // 'ngrok-skip-browser-warning': true,
      // 'Access-Control-Allow-Origin': '*',
      ...headers
    };
    if (options.withNoToken) {
      return { headers };
    }

    const token = getAuthToken();
    if (!token) {
      return { headers };
    }
    const deleteBody = {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      },
      ...options
    };
    if (params) {
      return {
        ...deleteBody,
        data: {
          ...params
        }
      };
    }

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      },
      ...options
    };
  }

  getAxiosObject() {
    axios.interceptors.request.use((config) => {
      /*This Axios interceptor checks the time difference between the current and last API call.
       *If more than 20 seconds have passed, it triggers the internet speed calculation.
       *The current time is then stored for future requests to compare.*/
      const currentCallTime = Date.now();
      const lastApiCallTime = storageFactory().getItem(getInterNetSpeedKey());

      // If the last API call time exceeds 20 seconds, calculate the internet speed.
      if (
        lastApiCallTime &&
        currentCallTime - Number(lastApiCallTime) > 20000
      ) {
        calculateInternetSpeed();
      }
      storageFactory().setItem(
        getInterNetSpeedKey(),
        currentCallTime.toString()
      );
      return config;
    });

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          return swal({
            title: 'Error',
            text: error?.response?.data?.detail,
            icon: 'error',
            button: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true
            }
          }).then((val) => {
            window.location.href = '/login';
            window.location.reload();
          });
        }
        const url = error?.request?.responseURL?.split('/');
        const checkUrl = [
          'create_whatsapp_info',
          'signing_up_invited_team_member',
          'create_domain'
        ];
        let result = url?.some((i) => checkUrl.includes(i));
        if (!result) {
          const hasArrayError = () => {
            if (
              Array.isArray(error.response?.data) &&
              error.response?.data.every((i) => typeof i === 'string')
            ) {
              return error.response?.data.join('');
            } else {
              return false;
            }
          };

          swal({
            title: 'Error',
            text: hasArrayError() || error?.message,
            icon: 'error',
            button: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true
            }
          });
        }
        if (
          error?.request?.responseURL
            ?.split('/')
            .includes('connect_ebot_to_ebox')
        ) {
          swal({
            title: 'Error',
            text: error?.response?.data?.error,
            icon: 'error',
            button: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true
            }
          });
          localStorage.removeItem(EBOT_EMAIL);
          localStorage.removeItem(BOT_ID);
        }
        if (error?.request?.responseURL?.split('/').includes('upgrade_plan')) {
          swal({
            title: 'Error',
            text:
              error?.response?.data?.error || error?.response?.data?.message,
            icon: 'error',
            className: 'swal-sender-email',
            button: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true
            }
          });
        }
        if (
          error?.request?.responseURL
            ?.split('/')
            .includes('exchange_fb_messenger_oauth_code')
        ) {
          swal({
            title: 'Error',
            text:
              error?.response?.data?.error || error?.response?.data?.message,
            icon: 'error',
            className: 'swal-sender-email',
            button: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true
            }
          });
        }
        if (
          error?.request?.responseURL
            ?.split('/')
            .includes('exchange_fb_lead_ads_oauth_code')
        ) {
          swal({
            title: 'Error',
            text: error?.response?.data?.message,
            icon: 'error',
            className: 'swal-sender-email',
            button: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true
            }
          });
        }

        if (error?.request?.responseURL?.split('/').includes('edit_email')) {
          swal({
            title: 'Error',
            text: error?.response?.data?.error.join(' , '),
            icon: 'error',
            className: 'swal-sender-email',
            button: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true
            }
          });
        }
        if (
          error?.request?.responseURL?.split('/').includes('create_patient')
        ) {
          swal({
            title: 'Error',
            text: getErrorResponse(error?.response?.data?.message),
            icon: 'error',
            className: 'swal-sender-email',
            button: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true
            }
          });
        }

        if (
          error?.request?.responseURL
            ?.split('/')
            .includes('accept_and_verify_otp')
        ) {
          swal({
            title: 'Error',
            text: error?.response?.data?.message,
            icon: 'error',
            button: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true
            }
          });
        }

        if (
          error?.request?.responseURL?.split('/').includes('connect_care_stack')
        ) {
          swal({
            title: 'Error',
            text: error?.response?.data?.error?.error_description,
            icon: 'error',
            button: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true
            }
          });
        }

        if (error?.request?.responseURL?.split('/').includes('invite_user')) {
          swal({
            title: 'Error',
            text: error?.response?.data?.error[0],
            icon: 'error',
            button: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true
            }
          });
        }

        return Promise.reject(error);
      }
    );
    return axios;
  }

  get(url, query = {}, headers = {}, options = {}) {
    return this.getAxiosObject().get(
      `${url}${toQueryString(query)}`,
      this.getConfig(headers, options)
    );
  }

  post(url, params, headers = {}, options = {}) {
    return this.getAxiosObject().post(
      url,
      params,
      this.getConfig(headers, options)
    );
  }

  put(url, params, headers = {}, options = {}) {
    return this.getAxiosObject().put(
      url,
      params,
      this.getConfig(headers, options)
    );
  }

  patch(url, params, headers = {}, options = {}) {
    return this.getAxiosObject().patch(
      url,
      params,
      this.getConfig(headers, options)
    );
  }

  delete(url, params, query = {}, headers = {}, options = {}) {
    return this.getAxiosObject().delete(
      `${url}${toQueryString(query)}`,
      this.getConfig(headers, options, params)
    );
  }
}

export default APIRequest;
