import CHOICES from 'choices';
import { isEmpty } from 'utils/utils';
import { isFirstCharDigit } from 'utils/utils';
import swal from 'sweetalert';

const { GBP, EUR, USD, AUD, AED } = CHOICES.CurrencyChoices;
const { CS_PHONES } = CHOICES.LeadChannelChoice;

export const cardDesign = (design) => {
  if (typeof design === 'string') {
    const parsedData = JSON.parse(design);
    return parsedData;
  }
  return design;
};

export const checkStageIsEmpty = (stageLeads) => {
  const { new_unread, reply_later, seen_replied } = stageLeads;
  return isEmpty(new_unread) && isEmpty(reply_later) && isEmpty(seen_replied);
};

export const getIdAndType = (leadId) => {
  const [id, key] = leadId.split('_');
  return { id, key };
};

export const getValuePlaceHolder = (text) => {
  switch (text) {
    case GBP:
      return '£ 0.00';
    case EUR:
      return '€ 0.00';
    case USD:
      return '$ 0.00';
    case AUD:
      return '$ 0.00';
    case AED:
      return ' د.إ 0.00';
    default:
      return '0.00';
  }
};

export const getCurrencyText = (text) => {
  switch (text) {
    case GBP:
      return '£';
    case EUR:
      return '€';
    case USD:
      return '$';
    case AUD:
      return '$';
    case AED:
      return 'د.إ';
    default:
      return '';
  }
};

export const getAvatarPadding = (name, channel, isSearch) => {
  if (channel === CS_PHONES && isFirstCharDigit(name)) {
    return isSearch ? 6 : 4;
  } else return 'unset';
};

export const getAppointmentStatusErrorPopUp = (cb) => {
  return swal({
    title: 'Error',
    text: 'This status is already linked to another stage. Please select different appointment status',
    icon: 'error',
    className: 'swal-sender-email',
    button: {
      text: 'Ok',
      value: true,
      visible: true,
      closeModal: true
    }
  }).then(() => {
    cb();
  });
};
